import { makeStyles, SvgIcon } from "@material-ui/core"
import React from "react"

const useStyles = makeStyles(() => ({
  customFontSizeInfo: {
    color: 'transparent',
    fontSize: '22px'
  },
}))

export const CoTextFieldInfoIcon = () => {
  const classes = useStyles()

  return (
    <SvgIcon classes={{ root: classes.customFontSizeInfo }} viewBox="0 0 16 16">
      <path
        d="M8.00008 1.83366C4.33341 1.83366 1.33341 4.83366 1.33341 8.50033C1.33341 12.167 4.33341 15.167 8.00008 15.167C11.6667 15.167 14.6667 12.167 14.6667 8.50033C14.6667 4.83366 11.6667 1.83366 8.00008 1.83366Z"
        stroke="#282828"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 11.167V7.83366"
        stroke="#282828"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.00366 5.83301H7.99767"
        stroke="#282828"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  )
}
