import { Typography, withStyles } from "@material-ui/core";

export const CoTypography = withStyles({
  caption: {
    fontSize: '12px',
    fontWeight: 500,
    letterSpacing: 'initial',
    lineHeight: '15.12px',
    textDecorationSkipInk: 'none',
    textUnderlinePosition: 'from-font'
  },
  root: {
    fontFamily: "'Plus Jakarta Sans', sans-serif"
  }
})(Typography);
