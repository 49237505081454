import React, { useState } from "react"
import { ClickAwayListener, Grid, makeStyles } from "@material-ui/core"
import { CoTooltip } from "../Tooltip/Tooltip"
import { CoTypography } from "../Typography/CoTypography"
import { CoTextFieldInfoIcon } from "./CoTextFieldLabelInfoIcon"
import { CoTextFieldLabelTooltipCloseIcon } from "./CoTextFieldLabelTooltipCloseIcon"
import { useTimeout } from "../../hooks/use-timeout"

const useStyles = makeStyles(() => ({
  customLineHeight: {
    lineHeight: 0
  },
  customPadding: {
    padding: '2px 0'
  },
  customWidth: {
    maxWidth: 210,
  },
}))

export type CoTextFieldLabelProps = {
  information: React.ReactNode
  label: React.ReactNode
}

export const CoTextFieldLabel = ({ information, label }: CoTextFieldLabelProps) => {
  const [tooltip, showTooltip] = useState(false)
  const classes = useStyles()
  const timeout = useTimeout(() => {
    handleTooltipClose()
  }, 4000)

  const handleLabelClick = () => {
    showTooltip(true)
    timeout.start()
  }

  const handleTooltipClose = () => {
    showTooltip(false)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Grid
        alignItems="center"
        container
        onClick={handleLabelClick}
        spacing={1}
        wrap="nowrap"
      >
        <Grid item>
          {label}
        </Grid>

        {information && (
          <CoTooltip
            arrow
            classes={{
              tooltip: classes.customWidth
            }}
            disableHoverListener
            disableFocusListener
            disableTouchListener
            open={tooltip}
            placement="top-start"
            title={
              <Grid
                alignItems="center"
                container
                direction="row"
                justify="center"
                wrap="nowrap"
              >
                <Grid classes={{ root: classes.customPadding }} item>
                  <CoTypography color="inherit" variant="caption">
                    Por favor, ingresa tu RUT como solicitante de la factura.
                  </CoTypography>
                </Grid>

                <Grid classes={{ item: classes.customLineHeight }} item>
                  <CoTextFieldLabelTooltipCloseIcon />
                </Grid>
              </Grid>
            }
          >
            <Grid classes={{ item: classes.customLineHeight }} item>
              <CoTextFieldInfoIcon />
            </Grid>
          </CoTooltip>
        )}
      </Grid>
    </ClickAwayListener>
  )
}
