import { Tooltip, withStyles } from "@material-ui/core";

export const CoTooltip = withStyles({
    arrow: {
        color: '#282828',
        left: '18px !important'
    },
    tooltip: {
        backgroundColor: '#282828',
        borderRadius: '8px',
        padding: '2px 8px'
    },
    tooltipPlacementTop: {
        margin: '4px -16px'
    }
})(Tooltip);
