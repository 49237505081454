import { useEffect, useRef, useState } from "react";

export const useTimeout = (callback: Function, delay: number) => {
    const [pid, setPID] = useState<unknown>();
    const savedCallback = useRef(callback);

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    const start = () => {
        if (pid) clearTimeout(pid as any);
        const newPID = setTimeout(() => savedCallback.current(), delay);
        setPID(newPID)
    };

    const clear = () => {
        if (pid) clearTimeout(pid as any);
        setPID(null)
    };

    useEffect(() => {
        start();
        return clear;
    }, [delay]);

    return { start, clear };
}
