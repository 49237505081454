import React, {
  useContext,
  useRef,
  useState,
  useEffect,
  MutableRefObject,
} from "react";
import Container from "../Container/Container";
import Invoice from "../Invoice/Invoice";
import Bill from "./Bill";
import InvoicePreview from "../Invoice/InvoicePreview";
import { useWindowSize } from "../../../../components/windowSize/use-window-size";
import PaymentDisplay from "../PaymentDisplay/PaymentDisplay";
import CheckOut from "../../services/CheckOut.service";
import { OrderformContext } from "../../contexts/orderform.provider";
import helpers from "../../../../utils/helpers";
import analytics from "../../../../utils/analytics";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import etPay from "../../../../assets/images/etPay.svg";
import warningIcon from "../../../../assets/images/warningIcon.svg";
import { FormControl, FormControlLabel, RadioGroup } from "@material-ui/core";
import { BlueRadio } from "../Shipping/Shipping";
import AlertDialog from "./../../../../components/AlertDialog/AlertDialog";
import WaitMessage from "../Oneclick/WaitMessage";
import { useLocation } from "react-router-dom";
import { decodeJwt } from "jose";
import DraggableContainer from "../../../../components/DraggableContainer";
import BUSINESS_TYPE from "../Invoice/giros.json";
import "./payment.styles.scss";

const useStyles = makeStyles(() => ({
  root: {
    color: "#02498E",
  },
}));

const Payment = ({
  setSelectedPaymentMethod,
  setSelectingDate,
  setSelectedCardType,
}: any) => {
  const [invoiceUpdateError, setInvoiceUpdateError] = useState(false);
  const {
    orderform,
    updateOrderform,
    valueMethodTransfer,
    setIsLoadingTransfer,
    isLoadingTransfer,
    setMethodTransfer,
    updateValidSections,
    loading,
    setLoading,
    isError,
    isErrorTransaction,
    updateGiftCard,
    setIsError,
    openModalTransferMethod,
    setErrorTransaction,
    updateOrderFormData,
    orderform: { paymentData },
  } = useContext(OrderformContext);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get("jwt");
  interface Card {
    type: "debit" | "credit";
    _id: string;
  }

  const updatePayments = async (): Promise<void> => {
    const response = await CheckOut.getCards();
    const arrayCards = response as Array<Card>;
    const dataPayment = {
      inscriptionType: arrayCards[0].type,
      inscriptionId: arrayCards[0]._id,
    };
    await CheckOut.updateCustomData(dataPayment, "inscriptioncard");
    const codePaymentRule: Record<Card["type"], number> = {
      debit: 201,
      credit: 202,
    };
    /**
     * The payment code to use.
     * @type {number}
     */
    const codePayment: number = codePaymentRule[arrayCards?.[0]?.type];
    /**
     * updated payments.
     * @type {PaymentData['payments']}
     */
    await CheckOut.updateSelectedPaymentType(codePayment);
    await CheckOut.ApiCheckout().then(async (response: any) => {
      await responseService(response, "oneClick");
    });
  };
  useEffect(() => {
    setIsLoadingTransfer(true);
    setLoading(true);
    if (!token && paymentData.cardsOneclick.length > 0) {
      const registrationStatus = query.get("registration");
      if (registrationStatus === "ok") {
        updatePayments();
      }
    }

    setValue("oneClick");
    validateSection("oneClick");
    setIsLoadingTransfer(false);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    token,
    orderform.paymentData,
    orderform.configurationFiles.methodsPayment,
    query,
  ]);

  useEffect(() => {
    if (token) {
      const decodeToken = decodeJwt(token);
      const { payment_status } = decodeToken || {};
      if (!payment_status) {
        handleActionTransferMethod({
          target: {
            id: "",
            name: "option1",
            value: true,
          },
        });
        setMethodTransfer(true);
        setErrorTransaction(true);
        setValue("etpay");
        analytics.interactiveEvent({
          action: "error-transaccion-mp",
          cardType: "Transferencia",
          label: "modal error en transaccion",
          paymentType: "Etpay",
        });
        analytics.addPaymentInfo({
          context: "error-transaccion",
          orderForm: orderform,
          paymentType: "Etpay",
          typeCard: "Transferencia",
        });
        return setIsError({
          title: "Error en la transacción",
          description:
            "Lo sentimos, ha ocurrido un problema al intentar realizar la transacción. Por favor, intenta nuevamente.",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const [formCorporateData, setFormCorporateData] = useState({
    corporateAddress: "",
    corporateComplement: "",
    corporateDocument: "",
    corporateGiro: "",
    corporateName: "",
    corporateNeighborhood: "",
    corporatePhone: "",
    corporateState: "",
    documentType: "",
    purchaseOrder: "",
    corporateCity: "",
    corporateNumber: "",
    corporateRubro: "",
    corporateStreet: "",
    document: "",
    email: "",
    firstName: "",
    lastName: "",
  });
  const [showTooltipTransfer, setShowTooltipTransfer] =
    useState<boolean>(false);

  const [giftCard, setGiftCard] = useState({
    numberGiftCard: "",
    passWordGiftCard: "",
    loading: false,
  } as any);

  const paymentSystem = orderform?.paymentData?.payments?.[0]?.paymentSystem;
  const [isOpen, setIsOpen] = useState(false);
  const [flagMessage, setFlagMessage] = useState(false);
  const [checked, setChecked] = useState(
    Boolean(
      orderform?.corporateData?.corporateDocument &&
        orderform?.corporateData?.corporateDocument !== "",
    ),
  );
  const isMobile = useWindowSize().width <= 711;
  const [modalGiftcard, setModalGiftcard] = useState<string>("");
  const [isOpenGiftcard, setIsOpenGiftcard] = useState<boolean>(false);
  const [value, setValue] = useState("oneClick");
  const classes = useStyles();

  const methodsPayment_filter =
    orderform?.configurationFiles.methodsPayment?.filter(
      (methodPayment: any) => {
        return methodPayment.state === 1 && methodPayment.id === "oneClick";
      },
    ) ?? [];
  const validationsFields =
    formCorporateData?.corporateDocument === "" ||
    !BUSINESS_TYPE.businessType.includes(formCorporateData?.corporateGiro);

  let opacityCss = loading ? 0.6 : 1;
  let punto: any = loading ? "none" : "all";

  const responseService = async (response: any, metodoPago: any) => {
    if (response.status === "OK") {
      await updateOrderform(response.data);
      setValue(metodoPago);
    }
    validateSection(metodoPago);
  };

  const addGiftCardService = () => {
    analytics.interactiveEvent({
      action: "Click",
      label: "Botón agregar gift card",
    });
    setGiftCard({ ...giftCard, loading: true });
    const sendService = {
      cardNumber: giftCard.numberGiftCard,
      cardPin: giftCard.passWordGiftCard,
      rut: orderform.clientProfileData.document,
    };

    CheckOut.giftCardBalance(sendService)
      .then(async (response: any) => {
        if (response.status === 201) {
          updateGiftCard({ data: response.data, sendService });
          if (!helpers.getStorage("giftCardStorage")) {
            const sendDataStorage = {
              numberCard: giftCard.numberGiftCard,
              balance: response.data.balance,
              id: response.data.id,
              inscriptionId: response.data.inscriptionId,
              cardPin: giftCard.passWordGiftCard,
            };
            helpers.setStorage(
              "giftCardStorage",
              JSON.stringify(sendDataStorage),
              null,
            );
          }
          setGiftcardPayment(
            response.data.id,
            response.data.balance,
            giftCard.numberGiftCard,
          );
          setGiftCard({ ...giftCard, loading: false });
        } else {
          setGiftCard({ ...giftCard, loading: false });
          setModalGiftcard(
            "No fue posible cargar tu Gift card, intenta nuevamente.",
          );
          setIsOpenGiftcard(true);
        }
      })
      .catch((_error) => {
        setGiftCard({ ...giftCard, loading: false });
        setModalGiftcard(
          "No fue posible cargar tu Gift card, intenta nuevamente.",
        );
        setIsOpenGiftcard(true);
        setIsLoadingTransfer(false);
        setLoading(false);
      });
  };

  const setGiftcardPayment = (id: any, balance: any, cardNumber: any) => {
    const paymentGiftCardSend = {
      idCard: id,
      balance: balance,
      cardNumber: cardNumber,
      inscriptionId: id,
      value: orderform.totalizerData.totalPayment.total,
    };
    setIsLoadingTransfer(true);
    CheckOut.giftCardPayment(paymentGiftCardSend)
      .then(async (response: any) => {
        await responseService(response, "giftcard");
        updateValidSections(helpers.SECTIONS.PAYMENT, true);
        setGiftCard({
          ...giftCard,
          passWordGiftCard: "",
          numberGiftCard: "",
        });
      })
      .catch((_error) => {
        setLoading(false);
        setIsLoadingTransfer(false);
        setModalGiftcard(
          "No fue posible cargar tu Gift card, intenta nuevamente.",
        );
        setIsOpenGiftcard(true);
      });
  };

  /**
  handleChangeRadio is a function that updates the selected payment method.
  @param {string} tempValue - The selected payment method value.
  @async
  @returns {void}
  */

  const handleChangeRadio = async (tempValue: string) => {
    setSelectingDate(true);
    // disable check transfer etpay
    setMethodTransfer(false);
    setLoading(true);
    setIsLoadingTransfer(true);
    analytics.interactiveEvent({
      action: `Click - ${tempValue}`,
      cardType: helpers.setCardType(paymentSystem),
      label: "Botón tipo de pago",
      paymentType: "oneClick",
    });
    await changeMethodPayment(tempValue);
    setValue(tempValue);
    setSelectedPaymentMethod(tempValue);
    setSelectingDate(false);
    analytics.interactiveEvent({
      action: "seleccionar-metodo-pago",
      cardType: helpers.setCardType(paymentSystem),
      label: helpers.setCardType(paymentSystem),
      paymentType: "oneClick",
    });
    setLoading(false);
    setIsLoadingTransfer(false);
  };

  const [openMessage, setOpenMessage] = useState(false);
  const handleOpenMessage = () => {
    setOpenMessage(!openMessage);
  };
  /**
   * Handle action transfer method
   *
   * @param {Object}  event :{ target:{ id:string; name:string; value:any; }; }
   * @returns {void}
   */
  const handleActionTransferMethod = async (event: {
    target: { id: string; name: string; value: any };
  }) => {
    // Extract the value property from the target of the event
    const { value } = event.target;
    // Update the state of the openModalTransferMethod and set the value of valueMethodTransfer
    setMethodTransfer(value);
    setSelectedPaymentMethod("etpay");
    setValue("etpay");
    setLoading(false);
    setIsLoadingTransfer(false);
    changeMethodPayment("etpay");
    // Call the CheckOut.transaction method
    analytics.interactiveEvent({
      action: "seleccionar-medio-pago",
      label: "seleccion de transferecia bancaria",
      paymentType: "Etpay",
      cardType: "Transferencia",
    });
  };

  const changeMethodPayment = async (methodPayment: any) => {
    setLoading(true);
    updateValidSections(helpers.SECTIONS.PAYMENT, false);
    if (methodPayment === "oneClick") {
      if (orderform.paymentData.cardsOneclick.length > 0) {
        const codePayment = orderform.configurationFiles.methodsPayment.find(
          ({ id }: { id: string }) => {
            return id.toLocaleLowerCase() === "oneClick".toLocaleLowerCase();
          },
        );
        await CheckOut.updateSelectedPaymentType(codePayment.code[0]).then(
          async (response: any) => {
            await responseService(response, "oneClick");
          },
        );
      } else {
        await CheckOut.ApiCheckout().then(async (response: any) => {
          await responseService(response, "oneClick");
        });
      }
    } else if (methodPayment === "webPay") {
      setFlagMessage(true);
      const codePayment = orderform.configurationFiles.methodsPayment.find(
        ({ id }: { id: string }) => {
          return id.toLocaleLowerCase() === "webPay".toLowerCase();
        },
      );
      await CheckOut.updateSelectedPaymentType(codePayment.code[0]).then(
        async (response: any) => {
          await responseService(response, "webPay");
        },
      );
    } else if (methodPayment === "giftcard") {
      setFlagMessage(true);

      if (helpers.getStorage("giftCardStorage")) {
        const tempValueGiftCard = helpers.getStorage("giftCardStorage") || "";
        const valueGiftCard = JSON.parse(tempValueGiftCard);
        setGiftcardPayment(
          valueGiftCard.id,
          valueGiftCard.balance,
          valueGiftCard.numberCard,
        );
      } else {
        responseService({ response: { status: "noOK" } }, "giftcard");
      }
    }
    setLoading(false);
  };

  const validateSection = (valor: string) => {
    let flag = false;
    if (valor === "oneClick") {
      if (orderform.paymentData?.cardsOneclick?.length > 0) {
        flag = true;
      }
    } else if (valor === "webPay") {
      flag = true;
    } else if (valor === "giftcard") {
      let giftCardVariant = orderform.paymentData?.giftCards
        ? orderform.paymentData.giftCards.filter(
            (x: any) => x.inUse === true,
          )[0]?.inUse
        : false;

      if (giftCardVariant) {
        flag = true;
      }
    }
    updateValidSections(helpers.SECTIONS.PAYMENT, flag);
  };

  const handleToggle = async () => {
    setIsLoadingTransfer(true);
    setLoading(true);
    analytics.interactiveEvent({
      action: "boton necesito factura",
      label: "necesito factura",
    });
    const hasFormCorporateData = formCorporateData?.corporateDocument !== "";

    if (!checked && orderform.hideTransfer) {
      setMethodTransfer(false);
      setValue("oneClick");
      validateSection("oneClick");
      handleChangeRadio("oneClick");
      setSelectedPaymentMethod("oneClick");
    }

    if (!checked && hasFormCorporateData) {
      setChecked(!checked);
    }

    if (!checked) {
      setIsOpen(true);
      return;
    }

    // Cuando se selecciona  la factura
    setChecked(!checked);
    setShowTooltipTransfer(!checked);
    if (checked && hasFormCorporateData) {
      try {
        await CheckOut.deleteBill();
      } catch (error) {
        setIsLoadingTransfer(false);
        setLoading(false);
        return;
      }
      let cleanData: any = {};
      for (const item of Object.keys(formCorporateData)) {
        cleanData[item] = "";
      }
      await CheckOut.updateClientProfile({
        ...orderform.clientProfileData,
        isCorporate: false,
      });
      updateOrderFormData({ corporateData: cleanData });
    }
    try {
      const response = await CheckOut.ApiCheckout();
      updateOrderFormData({ hideTransfer: response?.hideTransfer });
      if (response?.hideTransfer && value === "etpay") {
        setMethodTransfer(false);
        setValue("oneClick");
        validateSection("oneClick");
        setSelectedPaymentMethod("oneClick");
      }
    } catch (error) {
      setIsLoadingTransfer(false);
      setLoading(false);
    }
    setLoading(false);
    setIsLoadingTransfer(false);
  };

  useEffect(() => {
    if (!checked) return;
    if (checked) {
      setTimeout(() => {
        setShowTooltipTransfer(false);
      }, 5000);
    }
  }, [checked, showTooltipTransfer]);

  useEffect(() => {
    if (valueMethodTransfer) {
      setValue("etpay");
      return setSelectedPaymentMethod("etpay");
    }
    if (orderform.paymentData?.payments.length > 0) {
      let paymentValuerCheckBox = parseInt(
        orderform.paymentData?.payments[0].paymentSystem,
      );

      if (paymentValuerCheckBox === 30) {
        setValue("webPay");
        validateSection("webPay");
        setSelectedPaymentMethod("webPay");
      } else {
        validateSection("oneClick");
        setSelectedPaymentMethod("oneClick");
      }
    } else {
      let giftCardVariant =
        orderform.paymentData.giftCards?.filter(
          (x: any) => x.inUse === true,
        )[0] || false;

      if (giftCardVariant) {
        setValue("giftcard");
        validateSection("giftcard");
        setSelectedPaymentMethod("giftCard");
      } else {
        validateSection("oneClick");
        setSelectedPaymentMethod("oneClick");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methodsPayment_filter, orderform.paymentData]);

  useEffect(() => {
    const getUserInfo = async () => {
      const responseUser = await CheckOut.getUser();
      if (responseUser) {
        const purchaseOrder =
          orderform?.customData?.customApps?.find(
            ({ id }: { id: string }) => id === "purchaseorderdata",
          )?.fields?.purchaseOrder ?? "";
        const clientProfileData = JSON.parse(
          orderform?.customData?.customApps?.find(
            ({ id }: { id: string }) => id === "clientprofiledata",
          )?.fields?.corporateData || "{}",
        );
        let newCorporateData: any = { purchaseOrder };
        Object.entries(responseUser).forEach((item: any) => {
          let value = /(?:null|undefined)/.test(`${item[1]}`) ? "" : item[1];
          newCorporateData[item[0]] = value || "";

          if (item[0] === "corporateGiro") {
            const valueReference = value?.toUpperCase();
            const generateValue =
              BUSINESS_TYPE.businessType?.find(
                (item) => item === valueReference,
              ) ||
              BUSINESS_TYPE.businessType?.find(
                (item) => item === clientProfileData?.corporateGiro,
              ) ||
              "";
            newCorporateData[item[0]] = generateValue;
          }
          if (item[0] === "corporateName") {
            newCorporateData[item[0]] =
              value || clientProfileData?.corporateName || "";
          }
        });

        setFormCorporateData(newCorporateData);
        updateOrderFormData({ corporateData: newCorporateData });
      }
    };

    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  const hiddenTooltipTransfer = checked && orderform.hideTransfer;
  useEffect(() => {
    // Update the state of the openModalTransferMethod and set the value of valueMethodTransfer
    if (orderform.hideTransfer && checked && valueMethodTransfer) {
      let paymentValuerCheckBox = parseInt(
        orderform.paymentData?.payments[0].paymentSystem,
      );
      if (paymentValuerCheckBox === 30) {
        setValue("webPay");
        validateSection("webPay");
        setSelectedPaymentMethod("webPay");
      } else {
        validateSection("oneClick");
        setSelectedPaymentMethod("oneClick");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, orderform.hideTransfer]);

  const ref: MutableRefObject<HTMLDivElement> = useRef(
    document.createElement("div"),
  );
  const handleCloseUpdateInvoiceError = () => {
    setInvoiceUpdateError(false);
  };
  const handleCloseErrorTransaction = () => {
    setErrorTransaction(false);
    if (token) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete("jwt");
      const newUrl = window.location.pathname + "" + searchParams.toString();
      window.history.pushState({}, "", newUrl);
    }
  };
  if (Object.keys(methodsPayment_filter).length > 0) {
    return (
      <>
        {invoiceUpdateError && (
          <AlertDialog
            btnText="Aceptar"
            isLoading={false}
            isOpen={invoiceUpdateError}
            modalType="alert"
            title="Ocurrió un error al actualizar la factura intente nuevamente."
            handleClose={() => {
              handleCloseUpdateInvoiceError();
            }}
            handleSubmit={() => {
              handleCloseUpdateInvoiceError();
            }}
          >
            <p>{isError.description}</p>
          </AlertDialog>
        )}

        <Container title="Pago:">
          <Bill
            isOpen={checked}
            disable={isLoadingTransfer || loading}
            handleToggle={handleToggle}
          />
          {orderform.corporateData && checked && (
            <InvoicePreview
              setIsOpen={setIsOpen}
              formCorporateData={formCorporateData}
            />
          )}
          <Invoice
            setInvoiceUpdateError={setInvoiceUpdateError}
            formCorporateData={formCorporateData}
            handleChangeRadio={handleChangeRadio}
            isOpen={isOpen}
            isChecked={checked}
            setCheckedSwitch={setChecked}
            setFormCorporateData={setFormCorporateData}
            setIsOpen={setIsOpen}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
            setValue={setValue}
            validateSection={validateSection}
            value={value}
          />
          <div
            style={{
              position: "relative",
              opacity: opacityCss,
              pointerEvents: punto,
            }}
          >
            {(loading || isLoadingTransfer) && (
              <CircularProgress
                className={classes.root}
                size={60}
                style={{
                  position: "absolute",
                  top: isMobile ? "9000px" : "100px",
                  left: isMobile ? "50%" : "40%",
                  zIndex: 1,
                }}
              />
            )}
            {PaymentDisplay({
              data: methodsPayment_filter,
              display: isMobile ? "mobile" : "desktop",
              flagMessage,
              giftCard,
              isOpenGiftcard,
              modalGiftcard,
              setSelectedCardType,
              setSelectingDate,
              value,
              addGiftCardService,
              handleChangeRadio,
              setGiftCard,
              setIsOpenGiftcard,
            })}
          </div>
          <div className="container-transfer">
            <div className="container-transfer__tooltip">
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  aria-disabled={hiddenTooltipTransfer}
                  value={valueMethodTransfer}
                  onChange={
                    isLoadingTransfer
                      ? () => {
                          return;
                        }
                      : handleActionTransferMethod
                  }
                >
                  <FormControlLabel
                    control={<BlueRadio />}
                    disabled={hiddenTooltipTransfer || isLoadingTransfer}
                    label=""
                    value={
                      valueMethodTransfer ? valueMethodTransfer : "option1"
                    }
                  />
                </RadioGroup>
              </FormControl>
              <img
                alt=""
                className={`image_etpay ${hiddenTooltipTransfer ? "disabled_image" : ""}`}
                src={etPay}
              />
              <h3
                className={`container-transfer__method_payment ${hiddenTooltipTransfer ? "disabled_text" : ""}`}
              >
                Transferencia bancaria
              </h3>
              {hiddenTooltipTransfer && (
                <div
                  onClick={() => {
                    return handleOpenMessage();
                  }}
                >
                  <img alt="" className="warning-icon" src={warningIcon} />
                </div>
              )}
              {openMessage && !isMobile && (
                <div
                  className={`tooltiptext ${hiddenTooltipTransfer ? "tooltiptext_visible" : ""}`}
                >
                  <span>
                    El pago con transferencia no está disponible en este momento
                    para factura. Puedes utilizar los otros medios de pago
                    disponibles para realizar tu compra.
                  </span>
                </div>
              )}
            </div>
          </div>
          <WaitMessage title="Espera un poco" isOpen={openModalTransferMethod}>
            Nos estamos conectando de forma segura con tu transferencia bancaria
          </WaitMessage>
          <AlertDialog
            btnText="Aceptar"
            isLoading={false}
            isOpen={isErrorTransaction}
            modalType="alert"
            title={isError.title}
            handleClose={() => {
              handleCloseErrorTransaction();
            }}
            handleSubmit={() => {
              handleCloseErrorTransaction();
            }}
          >
            <p>{isError.description}</p>
          </AlertDialog>
          {hiddenTooltipTransfer && (
            <DraggableContainer
              halfScreen
              dragRatio={0.1}
              isOpen={openMessage && isMobile}
              minHeightFullScreen="216px"
              minFullscreenTranslation={200}
              modalRef={ref}
              onClose={() => {
                return handleOpenMessage();
              }}
              padding={0}
            >
              <>
                <div className="alert-danger">
                  <div className="alert-danger__header">
                    <h2>información importante</h2>
                  </div>
                  <p className="paragraph_description">
                    El pago con transferencia no está disponible en este momento
                    para factura. Puedes utilizar los otros medios de pago
                    disponibles para realizar tu compra.
                  </p>
                </div>
              </>
            </DraggableContainer>
          )}
        </Container>
      </>
    );
  }

  return (
    <Container title="Pago:">
      <div className="invoice-option"></div>
    </Container>
  );
};

export default Payment;
