import { makeStyles, SvgIcon } from "@material-ui/core"
import React from "react"

const useStyles = makeStyles(() => ({
  customFontSize: {
    fontSize: '14px'
  },
}))

export const CoTextFieldLabelTooltipCloseIcon = () => {
  const classes = useStyles()

  return (
    <SvgIcon classes={{ root: classes.customFontSize }} viewBox="-2 -2 14 14">
      <path
        d="M9.57546 0.864108C9.69696 0.742611 9.69696 0.545627 9.57546 0.42413C9.45397 0.302634 9.25698 0.302634 9.13549 0.42413L4.99992 4.5597L0.864352 0.42413C0.742855 0.302634 0.545871 0.302634 0.424374 0.42413C0.302878 0.545627 0.302878 0.742611 0.424374 0.864108L4.55994 4.99967L0.424374 9.13524C0.302878 9.25674 0.302878 9.45372 0.424374 9.57522C0.545871 9.69672 0.742855 9.69672 0.864352 9.57522L4.99992 5.43965L9.13549 9.57522C9.25698 9.69672 9.45397 9.69672 9.57546 9.57522C9.69696 9.45372 9.69696 9.25674 9.57546 9.13524L5.4399 4.99967L9.57546 0.864108Z"
        fill="white"
        stroke="white"
        stroke-width="0.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  )
}
